import {bindable, customElement, inject} from 'aurelia-framework';
import moment from 'moment';

@customElement('itinerary-display')
export class ItineraryDisplay {

    months = [
        'JAN',
        'FEB',
        'MAR',
        'APR',
        'MAY',
        'JUN',
        'JUL',
        'AUG',
        'SEP',
        'OCT',
        'NOV',
        'DEC',
    ];

    @bindable tableClass = 'table table-striped table-condensed';

    @bindable itinerary;

    _getDate(date)
    {
        //We use regular moment here, as we do not want timezones here
        date = moment.utc(date);

        let month = date.format('M');

        return date.format('DD') + this.months[month - 1];
    }

    _getTime(date)
    {
        //We use regular moment here, as we do not want timezones here
        date = moment.utc(date);

        return date.format('HH:mm');
    }

    _getFullDate(date)
    {
        //We use regular moment here, as we do not want timezones here
        date = moment.utc(date);

        return date.format('LL');
    }

    _getDiff(date1, date2)
    {
        date1 = moment.utc(date1);
        date2 = moment.utc(date2);

        if (date1.format('DDMMYYYY') === date2.format('DDMMYYYY')) {

            return '';
        }

        let month = date2.format('M');

        return date2.format('DD') + this.months[month - 1];
    }

    _hasDiff(date1, date2)
    {
        date1 = moment.utc(date1);
        date2 = moment.utc(date2);

        return date1.format('DDMMYYYY') !== date2.format('DDMMYYYY');
    }

    _getExtra(segment)
    {
        let extras = [];
        let extrasTitle = [];

        if (segment.cabinClass == 'economy') {
            extras.push('Econ.');
            extrasTitle.push('Economy');
        } else if (segment.cabinClass == 'premiumEconomy') {
            extras.push('Prem.');
            extrasTitle.push('Premium Economy');
        } else if (segment.cabinClass == 'business') {
            extras.push('Busi.');
            extrasTitle.push('Business');
        } else if (segment.cabinClass == 'first') {
            extras.push('First');
            extrasTitle.push('First');
        }

        if (segment.baggageAllowance) {

            extras.push(segment.baggageAllowance + 'PC');

            if (segment.baggageAllowance == 1) {
                extrasTitle.push(segment.baggageAllowance + ' Gepäckstück');
            } else {
                extrasTitle.push(segment.baggageAllowance + ' Gepäckstücke');
            }
        }

        if (segment.baggageWeight) {

            extras.push(segment.baggageWeight + 'KG');
            extrasTitle.push(segment.baggageWeight + ' KG Gepäckstück');
        }

        return [
            extras.join(' / '),
            extrasTitle.join(' / '),
        ];
    }

    _formatTime(value) {
        if (!value) {
            return null;
        }

        let negative = false;
        if (value < 0) {
            negative = true;
            value = Math.abs(value);
        }

        let hours   = Math.floor(value / 3600);
        let minutes = Math.floor((value - (hours * 3600)) / 60);

        if (hours   < 10) {hours   = "0"+hours;}
        if (minutes < 10) {minutes = "0"+minutes;}

        return (negative ? '-' : '') + hours+':'+minutes;
    }
}
